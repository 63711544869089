.news {
    /* font-size: 22px;
    font-weight: 600; */
    text-align: left;
}

.img-container {
    width: 50%;
    height: auto;
}

.imgss {
    width: 100%;
    height: 235px;
}

.blog_name {
    margin-left: 10px;
    text-align: initial;
}

.imgss img {
    border-radius: 10px 0px 0px 10px;
    object-fit: cover;
}

.all-blogs {
    text-align: left;
}

.all-blogs a {
    text-decoration: none;
    color: #414141;
}

.blog-title {
    color: #2F2F2F;
}

.desciption {
    color: #919395;
}

.date-format {
    color: #919395;
}

.read-moree {
    color: #17181A;

}