.form input {
    height: 48px;
    display: block;
    margin: auto;
    width: 100%;
    border: 1px solid #000000;
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 34px;
    background: transparent;
    color: #232429;
}

.login-img {
    /* background-image: url("../../../public//assets/img/background-img.png"); */
    background-size: cover;
}