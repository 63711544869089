.model-names {
    border: 1px solid #F2F2F2;
    padding: 15px 15px 0;
}

.model-names img {
    border-radius: 10px;
    aspect-ratio: 1 / 0.65;
}

.model-car-name {
    text-align: center;
    font-weight: 700;
    font-size: 12px;
}