.latest-car-content {
    display: flex;
    gap: 56px;
}

button.more-details-btn.me-2 {
    border-radius: 6px;
    padding: 5px 8px 5px 8px;
    border: 1px solid #0F563D;
    background: white;
    color: #0F563D;
}

.compare-btn {
    border-radius: 6px;
    padding: 5px 16px 5px 16px;
    border: 1px solid #0F563D;
    background: #0F563D;
    color: #fff;
}

.model-list {
    gap: 20px;
}

.model-list li {
    text-decoration: none;
}

.navLinks {
    color: #65676B;
}

a.navLinks.nav-link {
    color: #65676B;
}

/* .card-height.mb-3.latest-car-modal.card {
    height: 90%;
} */



.pricess {
    text-align: left;
}

.car-card {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-around;
}

.searched-car-section {
    border: 1px solid #E9E9E9;
    padding: 10px;
    margin-top: 20px;
    border-radius: 10px;
}