/* .trusted-car {
    text-align: left;
}

.cards-imgaes {
    border-radius: 0px 0px 10px 10px !important
}

.trust-product-img {
    height: 100% !important;
    aspect-ratio: 1 / 0.8;
    object-fit: cover;
}

.trust-img1 {
    border-radius: 12px 0px 0px 0px !important;
}

.trust-img2 {
    border-radius: 0px 12px 0px 0px !important;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.vs-text {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: white;
    z-index: 2;
    background-color: black;
    border-radius: 50px;
    height: 33px;
    width: 33px;
}

.trust-title {
    text-align: left;
    font: Roboto;
}

.comparing-trust-model {
    border: 1px solid #0F563D !important;
    color: #0F563D;
}

.carsvs {
    position: absolute;
} */

.trusted-car {
    /* font-size: 22px;
    font-weight: 600; */
    text-align: left;
}

.cards-imgaes {
    border-radius: 0px 0px 10px 10px !important
}


.trust-img1 {
    border-radius: 12px 0px 0px 0px !important;
}

.trust-img2 {
    border-radius: 0px 12px 0px 0px !important;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Adjust opacity as needed */
    z-index: 1;
}


.trust-title {
    text-align: left;
    font: Roboto;
}

.comparing-trust-model {
    border: 1px solid #0F563D !important;
    color: #0F563D;
}

.carsvs {
    position: absolute;
}

.trust-product-img {
    height: 100% !important;
    aspect-ratio: 1 / 0.8;
    object-fit: cover;
}

@media (max-width: 768px) {
    .trust-product-img {
        height: auto !important;
        max-width: 100%;
    }

    .vs-text {
        top: 10% !important;
    }

}

.vs-text {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 22px;
    color: white;
    z-index: 2;
    background-color: black;
    border-radius: 50px;
    height: 40px;
    width: 40px;
    text-align: center;
}