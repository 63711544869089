.forget-pswd {
    /* background-image: url("../../../public/assets/img/background-img.png"); */
    background-size: cover;
}

.cards {
    background: rgb(255 255 255 / 50%);
    border-radius: 0;
}

.forgot-form h4 {
    text-align: center;
}

.forgot-form p {
    text-align: center;
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #232429;
}

.forgot-form input {
    height: 48px;
    display: block;
    margin: auto;
    width: 100%;
    border: 1px solid #000000;
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 34px;
    background: transparent;
    color: #232429;
}

.submit-btn {
    background-color: #232429;
    width: 172.03px;
    margin-top: 10px;
    height: 46.4px;
    display: block;
    margin: auto;
}

.return p {
    text-align: center;
}

.return a {
    font-family: var(--font);
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 26px;
    text-decoration-line: underline;
    color: #232429;
    text-transform: capitalize;
}


.text-danger {
    color: red;
    font-size: 14px;
    line-height: 14px;
    text-align: left;
}