/* .search-graph {
    height: 360px;
    width: 100%;
}

.modalcompare-cus .h3 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 65px;
    color: #000000;
}

.modalcompare-cus .h6 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 34px;
    color: #000000;
}

.modalcompare-cus p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 34px;
    color: #000000;
    margin-bottom: 8px;
}

.currency-p {
    font-size: 16px;

}

tspan {
    font-family: 'Roboto' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 25px !important;
    color: #000000 !important;
}

.porche-price-cus:before {
    content: "€";
    padding: 0 4px;
    color: #0f563d;
    font-weight: 600;
}

.main-avg-price-c div {
    width: 50%;
    display: inline-block !important;
    margin-bottom: 8px;
}

.takenprice {
    color: #0f563d;
}


@media only screen and (max-device-width: 767px) {
    .modal-contryname p {
        font-size: 14px;
        line-height: 25px;
    }

    .modalcompare-cus p {
        font-size: 14px;
        line-height: 25px;
    }

    .modalcompare-cus .h3 {
        font-size: 22px;
        line-height: 30px;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .newgraph-m {
        height: 100%;
    }


} */

.table tbody tr:nth-child(odd) {
    background-color: #DADCDD;
}

.table tbody tr:nth-child(even) {
    background-color: white;
}

.porche-price-cus:before {
    content: "€";
    padding: 0 4px;
    color: #0f563d;
    font-weight: 600;
}

/* 
table {
    border-collapse: collapse;
    width: 100%;
} */

/* th,
td {
    text-align: left;
    padding: 8px;
} */

tr:nth-child(even) {
    background-color: #FAFBFC;
}

.country-name {
    border-right: 1px solid #DADCDD;
}















.table-responsive {
    overflow-x: auto;
}

table {
    border-collapse: collapse;
    width: 100%;
}

th,
td {
    text-align: left;
    padding: 8px;
}

tr:nth-child(even) {
    background-color: #FAFBFC;
}

.country-name {
    border-right: 1px solid #DADCDD;
}