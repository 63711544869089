.latest-car-content {
    display: flex;
    gap: 56px;
}

.latest-car-modal {
    width: 33%;
    border-radius: 10px 10px 10px 10px;
}

button.more-details-btn.me-2 {
    border-radius: 6px;
    padding: 5px 8px 5px 8px;
    border: 1px solid #0F563D;
    background: white;
    color: #0F563D;
}

.compare-btn {
    border-radius: 6px;
    padding: 5px 16px 5px 16px;
    border: 1px solid #0F563D;
    background: #0F563D;
    color: #fff;
}


.car-card {
    height: 100%;
    /* display: flex; */
    flex-wrap: wrap;
    align-content: space-around;
}