.compare-carsss {
    background-image: url("../../assets/images/compare-car.png");
    background-repeat: no-repeat;
    width: 100%;
    min-height: 340px;
    background-size: contain;
    /* background-position: 100% center; */
}

.easy-way {
    color: #fff;
    font-size: 40px;
    padding: 20px 12px 12px 20px;

}

@media (max-width: 768px) {
    .compare-carsss {
        background-size: cover;
        background-position: center;
        min-height: 200px;
    }

    .easy-way {
        font-size: 30px;
        padding: 10px;
    }
}

.comapre-car-heading {
    color: #000000;

    font-weight: 500
}

.cars-your-choice {
    border: 1px solid #DEDEDF;
    padding: 40px 17px 12px 23px;
}

.confused {
    color: #919395;
    font: Roboto;
}



.search-graph {
    height: 360px;
    width: 100%;
}

tspan {
    font-family: 'Roboto' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 25px !important;
    color: #000000 !important;
}

.banner-img {
    /* background-image: url("../assets/img/banners.png"); */
    /* background-image: url("../../../public/assets/img/banners.png"); */
    height: 500px;
}

select.form-select {
    background: #FFFFFF;
    border: 2px solid #0F563D;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #696969;
}

section.banner-img button {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px !important;
    line-height: 28px !important;
    color: #FFFFFF;
}

.comparebtn {
    background: #69A84F;
    width: 230px;
}

.add-btn {
    background: #434A54;
    width: 170px;
}

.delete-compare {
    background: #D9D9D9;
    border-radius: 20px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 15px !important;
    line-height: 24px !important;
    color: #658D5C;
    height: 40px;
}

.price-details p {

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 18px;
    color: #000000;
    background: #D8EAD2;
    /* padding: 0 4px; */
    display: inline-block;
    margin-bottom: 4px;
    width: 100%;

}

p.highp {
    background: #92C37C;
    /* min-width: 140px; */
}

input[type="text"] {
    padding-left: 15px;
}

section.banner-img .h6 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
}

.lowestp {
    background: #B7D7A8 !important;
}

.compare-icon {
    width: 75px;
}

section.banner-img .card {
    min-height: 270px;
    /* max-height: 270px; */
}

.compare-images {
    background: #C0DAFF;
}

.compare-images img {
    height: 150px;
    display: block;
    width: 100%;
    object-fit: cover;
    object-position: bottom;
}

.card-custom {
    width: 18rem;
}


@media screen and (min-device-width: 600px) and (max-device-width: 996px) {
    .card-custom {
        width: 12rem;
    }

    select.form-select {
        font-size: 14px;
        line-height: 32px;
    }

    button.py-2.border-0.px-5.btn.add-btn.btn-dark.mb-3.mb-md-0.me-md-2.btn.btn-primary {
        margin-right: 10px;
    }

    .card-custom {
        margin: 0 6px;
    }

    section.banner-img .card {
        max-height: 100%;
    }

    .banner-img {
        height: 100%;
        background-size: cover;
    }

}

@media screen and (min-device-width: 370px) and (max-device-width: 600px) {
    .card-custom {
        width: 48% !important;
    }

    .card-custom {
        margin: 0 2px;
    }


}

.home-text {
    border: 1px solid #E9E9E9;
    border-radius: 10px;
    padding: 25px;
}


@media only screen and (max-device-width: 600px) {

    section.banner-img .card {
        max-height: 100%;
    }

    section.banner-img button {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px !important;
        line-height: 24px !important;
        color: #FFFFFF;
    }

    .card-custom {
        width: 48% !important;
    }

    .card-custom {
        margin: 0 2px;
    }

    .card-custom {
        width: 100% !important;
    }

    .banner-img {
        height: 100%;
        background-size: cover;
    }

    select.form-select {
        font-size: 14px;
        line-height: 26px;
        padding: 4px 6px;
    }

    .price-details p {
        font-size: 11px;
        padding: 3px 5px !important;
        line-height: 13px;
        width: 100%;
        padding: 0 4px;
    }

    p.highp {
        background: #92C37C;
        min-width: 100%;
    }

    .add-btn {
        width: 230px;
    }

    .comparecardiv-cus {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 8px;
    }

}


.card-custom img.card-img-top {
    height: 134px;
    object-fit: cover;
    object-position: center;
}

.compare-images img {
    height: 110px;
    display: inline-block;
    width: 50%;
    object-fit: cover;
    object-position: center;
}

.compare-images p {
    display: inline-block;
}

.select-container {
    height: 100px !important;
    /* Adjust the desired height for the container */
    overflow-y: auto !important;
}

.popup li {
    font-family: var(--font);
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 34px !important;
    color: #000000;
    cursor: pointer;
}

.popup ul {
    list-style: circle;
}

.popup h2 {
    font-family: var(--font);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-transform: capitalize;
}

.compare-images img {
    height: 190px;
    width: 100% !important;
    object-fit: cover;
    object-position: center;
}

.compareform .search-form {
    width: 75%;
}

.compareform .searh-btns {
    width: 25%;
}

.compareform .Search-btn {
    width: 100%;
    height: 51px !important;
    font-size: 16px !important;
}

.compareform .search-form .MuiInputBase-root {
    border-radius: 20px;
    margin-top: 4px;
    padding: 6px !important;
}

.compareform .search-form input#combo-box-demo,
.compareform .search-form input#combo-box-demo::placeholder {
    font-size: 14px !important;
}

.compareform label#combo-box-demo-label {
    line-height: 27px;
    font-weight: 400;
    font-size: 14px;
    color: #696969;
}

.card-custom img.card-img-top.compare-card-img {
    height: 240px;
}




@media only screen and (max-device-width: 767px) {

    svg.recharts-surface {
        width: 100% !important;
        height: 100% !important;
    }

    .recharts-wrapper {
        width: 100% !important;
        height: 100% !important;
    }

    .compareform label#combo-box-demo-label {
        line-height: 20px;
        font-size: 14px;
    }

    .compareform .search-form .MuiInputBase-root {
        border-radius: 10px;
        height: 46px;
        margin-bottom: 8px;
    }

    .compareform .Search-btn {
        width: 100%;
        border-radius: 10px !important;
        height: 46px !important;
        font-size: 15px !important;
    }

    .compareform .search-form {
        width: 100%;
        padding-left: 0.5rem;
    }

    .compareform .searh-btns {
        width: 36%;
        padding-left: 0.5rem;
    }

    .compareform {
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .popup h2 {
        font-size: 16px;
    }

    .popup {
        margin-bottom: 26px;
    }

    .popup li {
        font-size: 15px !important;
        line-height: 23px !important;
        margin-bottom: 12px;
    }

    .popup ul {
        padding-left: 21px;
    }

    .comparecardiv-cus {
        display: block;
    }

    .compare-images img {
        height: 130px;
    }

    .card-custom img.card-img-top.compare-card-img {
        height: 190px;
    }



}

/* .compare-images {
    position: relative;
}

.compare-images:before {
    content: "vs";
    position: absolute;
    background: #e6c481;
    font-size: 28px;
    font-weight: 600;
    font-family: cursive;
    width: 50px;
    outline: 4px solid #fff;
    color: #0f563d;
    height: 50px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 28px;
    padding-bottom: 5px;
    position: absolute;
    left: 43%;
    top: 44%;
} */

.addCricle {
    border: 1px dashed #24272c;
    border-radius: 50%;
    display: block;
    height: 72px;
    margin: 30px auto 8px;
    opacity: .5;
    -moz-opacity: .5;
    position: relative;
    width: 72px;
}

.comparing-car-btn {
    background: #0F563D !important;
    color: #fff !important;
    padding: 20px 45px 20px 45px !important;
    border-radius: 10px !important;
    font-size: 17px !important;
}

.card-img {
    min-width: 230px;
    min-height: 170px;
}