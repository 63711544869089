.home-contactf {
    /* background-image: url("../../assets/images/Contact.png"); */
    background-repeat: no-repeat;
    width: 100%;
    height: 380.43px;
}

#Name,
#email,
.message {
    background-color: transparent;
    border: 1px solid #000000;
    border-radius: 10px !important;
}

.contactus-form {
    background-color: transparent;
    border: 0px;
}

.home-contactf input {
    border: 1px solid #BDBEBF;
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 46px;
    height: 46px !important;
}

.home-contactf input::placeholder {
    font-size: 14px;
    line-height: 46px;
    color: #000000;

}

.home-contactf textarea::placeholder {
    font-size: 14px;
    line-height: 46px;
    color: #000000;

}

.home-contactf textarea {
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 46px;

}

.submit-button {
    background-color: #0F563D;
    color: #fff;
    padding: 7px 50px 7px 50px;
    border: 0px;
    border-radius: 10px;
}

.contact-submit-button {
    background: #0F563D !important;
    color: #fff !important;
    padding: 5px 40px 5px 40px !important;
}