.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 10px;
}

.Search-btn {
    border-radius: 10px;
}

.justify-content-center.mb-5.col-md-4 {
    display: flex !important;
    align-items: end !important;
    margin-bottom: 0 !important;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-colorPrimary.Search-btn.text-capitalize.css-sghohy-MuiButtonBase-root-MuiButton-root {
    width: 85%;
    font-size: 18px;
    padding: 8px 0px 8px 0px;
    border-radius: 10px;
    background: #0F563D;
}

.country-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.country-card {
    margin: 10px;
    text-align: center;
    border: 1px solid #F2F2F2;
    width: 14%;
    height: 120px;
}

.country-card1 {
    margin: 10px;
    text-align: center;
    width: 14%;
    ;
}

.country-img {
    width: 32px;
}

.home-text {
    border: 1px solid #E9E9E9;
    border-radius: 10px;
    padding: 25px;
}


.home-text h5 {
    font-weight: 600;
}

.home-text p {
    color: #414141;
}



@media only screen and (max-width: 600px) {
    .country-card {
        margin: 10px;
        text-align: center;
        border: 1px solid #F2F2F2;
        width: 30%;
        height: 120px;
    }
}


/* .country-label-img::after {
    content: "";
    width: 50px;
    height: 50px;
    background: aquamarine;
    position: absolute;
    z-index: -1;
    border-radius: 50%;
} */

/* #EFF9FF */
.country-label-img {
    background: #EFF9FF;
    padding: 10px;
    width: 50px;
    border-radius: 50%;
    height: 50px;
    justify-content: center;

}


#homemapdata {
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    display: block;
}

/* #homemapdata {

    top: 0px;
    position: fixed;
} */

.banner-content {
    background-image: url("../../assets/images/banner.png");
    background-repeat: no-repeat;
    width: 100%;
    padding: 30px 0 30px 0px;
    /* background-position: center; */
    /* height: 7000px !important; */
    background-size: cover;
}

/* .banner-content {
    background-image: url("../../assets/images/banner.png");
    background-repeat: no-repeat;
    width: 100%;
    padding: 30px 0 30px 0px;
    background-position: center;


} */

.banner-imgesss {
    align-items: end !important;
    display: flex !important;
    margin-bottom: 0 !important;
}


.welcome {
    text-align: justify;
    padding: 32px 0px 10px 6px;
    font-size: 18px;
    /* margin-top: 50px; */
}

.prices {
    color: #E6B75B;
    font-size: 32px;
    font-weight: 700;
}

.view-map {
    font-size: 18px;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-colorPrimary.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-colorPrimary.mb-3.step1-btn.css-sghohy-MuiButtonBase-root-MuiButton-root {
    padding: 10px 15px 10px 15px;
    border-radius: 10px;
    font-size: 10px;
}

.autocompltes-steps {
    padding: 4px 7px 3px 19px;

}

.dxm-control-bar {
    display: none;
}