.contact-us-img {
    /* background-image: url("../../../public/assets/img/background-img.png"); */
    background-size: cover;
}

.contact-us-img h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: #232429;
}

.conatct-us h2 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 28px;
    color: #232429;
}

.conatct-us h3 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #232429;
}

.conatct-us p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 28px;
    color: #232429;
    mix-blend-mode: normal;
}

.conatct-us p span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #919395;
}

.conatct-us h4 {
    font-size: 25px;
}

.conatct-us p.info-email {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 28px;
    color: #232429;
}

.icons {
    font-size: 27px;
    color: #004226;
}

.contact-form {
    width: 100%;
    border: 1px solid #004226;
}

.contact-form input {
    Height: 55px;
}

/* .contact-form Textarea {
     height: 125.83px;
     width: 600.23px;
 } */
.search-btn {
    background-color: #004226 !important;
    width: 172.03px;
    display: block !important;
    margin: auto !important;
    margin-top: 10px;
    height: 46.4px;
    color: #E6B75B;
}

.contact-form input {
    Height: 50px !important;
    width: 100%;
    border: 1px solid #BDBEBF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 29px;
    color: #919395;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    border-radius: 0px !important;
}

.countary-field {
    height: 50px;
    width: 100%;
    border: 1px solid #BDBEBF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 29px;
    color: #919395;
}

.text-area-cus {
    width: 100%;
    border: 1px solid #000;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 29px;
    color: #000;
}

.text-area-cus::placeholder {
    color: #000;
}

.address {
    font-size: 20px !important;
}