.productdetail .h4 {
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 30px !important;
    color: #414141;
}

.productdetail .taken {
    font-size: 16px;
    line-height: 28px;
}

.productdetail .takenp {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
}

.takemebtn {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 38px;
    text-align: center;
    color: #fff;
    background: #0F563D;
    transition: all 0.6s ease;
    border-radius: 10px !important;
}

.contactbtn {
    background: #92C37C;
}

.askbtn {
    background: #D8EAD2;
}


.takemebtn:hover {
    color: #fff;
    background: #0F563D;
    transition: all 0.6s ease;
}

.product-slider .slick-slide img {
    display: block;
    width: 100%;
    height: 414px;
    object-fit: cover;
    object-position: center;
}

.second-p-slider img {
    height: 70px !important;
    width: 85px !important;
    padding: 5px;
}


.vehicleinfo .h4 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 26px !important;
    color: #414141 !important;
}

.vehicleinfo table .bordertr {
    border-bottom: 2px solid #D9D9D9 !important;
}

.vehicleinfo table th {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
    color: #414141;
    padding: 9px 14px 0;
}

.vehicleinfo table td {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-transform: capitalize;
    color: #0D4226;
    padding: 0 14px 8px;
}

.vehicleinfo table {
    border: 2px solid #D9D9D9;
}

p.takenC.d-inline-block {
    font-weight: 500;
    font-size: 13px;
}

.product-card-carousel .carousel-1.card {
    height: 96%;
}


@media only screen and (max-device-width: 600px) {
    .second-p-slider img {
        height: 54px !important;
        width: 54px !important;
    }

    div#root {
        overflow-x: hidden;
    }

    .productdetail .takenp {
        font-size: 17px;
        line-height: 26px;
    }

    .productdetail .h4 {
        font-size: 18px !important;
    }

    .productdetail .taken {
        font-size: 14px;
        line-height: 26px;
    }

    .vehicleinfo .h4 {
        font-size: 18px !important;
    }

    p {
        font-size: 16px;
        line-height: 30px;
    }

    p.takenC.d-inline-block {
        font-size: 14px;
    }


}


@media screen and (min-device-width: 600px) and (max-device-width: 1000px) {

    .productdetail .taken {
        font-size: 14px;
        line-height: 26px;
    }

    .productdetail .h4 {
        font-size: 18px !important;
    }

    .productdetail .takenp {
        font-size: 17px;
        line-height: 24px;
    }

    .h4 {
        font-size: 20px !important;
        line-height: 28px !important;
    }

    .second-p-slider img {
        height: 58px !important;
        width: 53px !important;
    }

    .carousel-1.card {
        width: 100%;
    }

    .h5 {
        font-size: 15px !important;
    }

    .choosebudget p {
        line-height: 16px !important;
    }

    .toppings-list-item label {
        font-size: 14px !important;
    }

    .appcus label {
        line-height: 16px !important;
    }


}


/* products.js */

.h5 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #414141;
}

.appcus {
    background: #FFFFFF;
    border: 1px solid #E9E9E9;
    border-radius: 10px;
}

.appcus label {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #242735;
}

.choosebudget p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 23px;
    color: #757575;
}

.toppings-list-item label {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #242735;
}

.toppings-list-item input {
    width: 16px;
    height: 16px;
}

.add-scroll {
    height: 171px;
    overflow-x: auto;
}

.comparemax {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    color: #757575;
}

.searchedp h3 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #414141;
}

.searchedp .takenC.capetext {
    font-size: 14px;
    color: #414141;
    font-weight: 600;
}

.searchedp .takenprice.miltext {
    font-weight: 500;
    font-size: 14px;
    color: #414141;
}

.searchedp {
    height: 500px;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

.addcompare {
    background: #E3E3E3;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    color: #242735;
}

.searchedp img {
    height: 178px;
    object-fit: cover;
    width: 100%;
}

span.MuiSlider-thumb.MuiSlider-thumbSizeMedium.MuiSlider-thumbColorPrimary.MuiSlider-thumb.MuiSlider-thumbSizeMedium.MuiSlider-thumbColorPrimary.css-eg0mwd-MuiSlider-thumb {
    border: 2px solid #0F563D;
    background: #ffffff;
    box-shadow: none;
}

span.MuiSlider-root.MuiSlider-colorPrimary.MuiSlider-sizeMedium.css-187mznn-MuiSlider-root {
    color: #0F563D;
    height: 2px;
}


.filterdata {
    border: 1px solid #D9D9D9;
    font-size: 14px;
    color: #757575;
    font-family: 'Roboto';
    line-height: 20px;
    font-weight: 400;
    padding: 3px 10px;
}


/*  */

.vehicle-info p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-transform: capitalize;
    color: #0D4226;
    padding: 0 0px 8px;
}

.second-p-slider img.botom-img {
    width: 100% !important;
}


.appcus p.takenprice.porche-price-cus {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 26px;
    color: #242735;
}

.border-custom {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    padding: 2px 10px;
    display: inline-block;
    font-family: 'Roboto';
    margin-bottom: 10px;
    margin-right: 10px;
}

@media only screen and (max-device-width: 767px) {

    .appcus label img {
        width: 26px !important;
        height: 17px;
        object-fit: cover;
    }

}

img.my-customicon {
    width: 20px;
}

h4.optionss {
    font-weight: 400;
    padding: 8px 0;
    text-transform: uppercase;
    font-size: 24px;
    color: #212529;
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    margin: 0 0 8px;
    text-align: left;
    width: 100%;
}

ul.options {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px;
}

ul.options li {
    text-transform: uppercase;
    padding: 4px;
    flex-basis: 33%;
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: 8px;
    list-style: none;
    text-align: left;
}



.column.s1 {
    width: 100%;
}

p.takenC.text.mb-3 {
    text-transform: uppercase;
    padding: 4px;
    flex-basis: 33%;
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: 8px;
}

.mileage {
    border: 2px solid;
    padding: 8px;
    border-radius: 10px;
}

.listingbtn {
    text-decoration: none;
}

.selected-product {
    color: #696969 !important;
}

.price-range {
    color: '#414141';
    font-size: "18px"
}

.searched-products-details {

    height: 470px;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

.image-container img {
    cursor: zoom-in;
    min-height: 180px;
    width: 100%
}

.product-slider img {
    width: 100%;
    height: 100%;
}