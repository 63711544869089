.login-img {
    /* background-image: url("../../../public//assets/img/background-img.png"); */
    background-size: cover;
}

.card-login {
    width: auto;
    margin-top: 50px;
}

.form h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 27px;
    text-align: center;
    color: #232429;
}

.card-login.card {
    background: rgb(255 255 255 / 50%);
    border-radius: 0;
    border: 0px;
}


.check-box {
    display: flex;
}

.check-box p {
    text-decoration: none;
}

.submit-btn {
    background-color: #232429 !important;
    width: 172.03px;
    margin-top: 10px;
    height: 46.4px;
    display: block !important;
    margin: auto !important;
    color: white !important;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 16px !important;
}

.sing-up {
    display: flex;
}

.form input {
    height: 48px;
    display: block;
    margin: auto;
    width: 100%;
    border: 1px solid #000000;
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 34px;
    background: transparent;
    color: #232429;
}

.form input::placeholder {
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 34px;
    color: #232429;
}

.forgetpwd {
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 26px;
    text-decoration: none;
    color: #232429;
}

.sing-up p {
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #232429;
}

.sing-up a {
    font-family: var(--font);
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 26px;
    text-decoration-line: underline;
    color: #232429;
}

.danger-text {
    color: red;
    font-size: 14px;
    line-height: 14px;
    text-align: left;
}