.footer {
    background: #F7F7F7;
}

.help {
    color: #515152;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
}

.address {
    gap: 20px;
}

.foo-about {
    font-size: 20px;
    color: #000000;
    font-family: Roboto;
    line-height: 35px;
}

.copyright {

    font-size: 16px;
    font-weight: 700;
    line-height: 25px;

}

.help a {
    text-decoration: none;
    color: #515152;
}