* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
}
 
.login-btn a {
    text-decoration: none;
    color: black;
}

.topbar {
    border-bottom: 1px solid #E1E3E1;
    padding: 10px 0;
}

.banner-section {
    background-image: url("../../assets/images/banner.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 40px 0;
}

.btn_primary {
    background-color: #0F563D;
    color: #fff;
}

.car-search {
    border-radius: 12px;
    padding: 25px 15px 75px 15px;
}

.fs-14 {
    font-size: 14px;
}

.banner-text h6 {
    color: #fff;
}

.banner-text h1 {
    color: #E6B75B;
    font-size: 32px;
}

.banner-text {
    padding: 40px 0 0 5px;
}

.takn {
    color: #414141;
}

.cost {
    color: #2F2F2F;
    font-weight: 600;
}

.more-detail {
    border: 1px solid #0F563D;
}

.car-card {
    border: 1px solid #E9E9E9;
    border-radius: 10px;
}

.card_text {
    padding: 15px;
}

.section-heading h2 {
    font-size: 22px;
    font-weight: 500;
}

.most-searched-content {
    padding-top: 50px;
}

.view-all {
    font-size: 14px;
    color: #414141;
    text-decoration: none;
    padding-top: 40px;
}

.Contact-Us {
    background-image: url("../../assets/images/Contact.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 40px 0;
}

.read-more {
    text-decoration: none;
    font-size: 14px;
    color: #000;
}

.choose_car {
    display: flex;
    flex-flow: column;
    height: 100%;
    justify-content: space-between;
}

.footer-p {
    color: #515152;
}

.footer-ul li {
    list-style: none;
}

.footer-ul li a {
    text-decoration: none;
    color: #515152;
}

.footer-ul {
    padding: 0;
}

.footer-content {
    padding: 80px 0;
}

.copyright {
    background-color: #E5E5E5;
    padding: 15px 0;
}

nav.navbar.navbar-expand-lg.bg-body-tertiary {
    background: white !important;
}

div#navbarSupportedContent {
    padding-left: 61px;
}

@media only screen and (max-width: 600px) {
    div#navbarSupportedContent {
        padding-left: 0px;
    }
}