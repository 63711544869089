p.dedicated {
    font-size: 20px;
    line-height: 32px;
    text-align: justify;
}

p.created {
    line-height: 32px;
    font-size: 20px;
    text-align: justify;
}
p.progressively {
    line-height: 32px;
    font-size: 25px;
    text-align: justify;
}

li.pricing {
    line-height: 32px;
    font-size: 20px;
    text-align: justify;
}

p.seasoned{
    line-height: 32px;
    font-size: 20px;
    text-align: justify;
}